<template>
  <v-card>
    <v-card-title>
      <v-btn
        id="btn1"
        color="primary"
        dark
        @click="dialogModal(true, 'create')"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        {{ $('create') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="data"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
        showFirstLastPage: true,
      }"
    >
      <template v-slot:item.type="{ item }">
        <v-chip v-if="+item.type === 0" small color="accent">
          السوق
        </v-chip>
        <v-chip v-else-if="+item.type === 1" small color="primary">
          البورصة
        </v-chip>
        <v-chip v-else-if="+item.type === 2" small color="warning">
          الذهب
        </v-chip>
        <v-chip v-if="+item.type === 1 && +item.is_closed === 1" small color="error" class="mx-1">
          مغلقة
        </v-chip>
        <v-chip v-if="+item.type === 1 && +item.is_closed === 0" small color="success" class="mx-1">
          مفتوحة
        </v-chip>
      </template>
      <template v-slot:item.sell_price="{ item }">
        {{ item.prices[0].sell_price | comma }} د.ع.
      </template>
      <template v-slot:item.buy_price="{ item }">
        {{ item.prices[0].buy_price | comma }} د.ع.
      </template>
      <template v-slot:item.price_update="{ item }">
        <v-btn small color="primary" @click="dialogModal(true, 'priceUpdate', item)">
          <v-icon
            left
            dark
          >
            mdi-currency-usd
          </v-icon>
          تحديث الاسعار
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <div id="actions" class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item)"></menu-item>
              <menu-item :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item)"></menu-item>
            </v-list>
          </v-menu>
          <v-btn v-if="+item.is_closed === 1 && +item.type === 1" class="mx-1" small color="success" @click="updateBursaState(item, 0)">
            <v-icon
              left
              dark
            >
              mdi-lock-open-variant-outline
            </v-icon>
            فتح
          </v-btn>
          <v-btn v-if="+item.is_closed === 0 && +item.type === 1" class="mx-1" small color="error" @click="updateBursaState(item, 1)">
            <v-icon
              left
              dark
            >
              mdi-lock-outline
            </v-icon>
            غلق
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <item-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></item-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import itemDialog from './dialog.vue'
import MenuItem from '@/components/menu/menuItem.vue';
import store from '@/store';
import toast from '@/store/toast'

export default {
  name: 'Currencies',
  components: { itemDialog, MenuItem },
  data: () => ({
    translationKey: 'pages.currencies',
    filters: false,
    data: [],
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'رقم العملة',
        value: 'id',
        align: 'center',
        sortable: false,
      },
      {
        text: 'اسم العملة',
        value: 'name',
        align: 'center',
        sortable: true,
      },
      {
        text: 'النوع',
        value: 'type',
        align: 'center',
        sortable: false,
      },
      {
        text: 'سعر البيع',
        value: 'sell_price',
        align: 'center',
        sortable: true,
      },
      {
        text: 'سعر الشراء',
        value: 'buy_price',
        align: 'center',
        sortable: true,
      },
      {
        text: 'تحديث الاسعار',
        value: 'price_update',
        align: 'center',
        sortable: true,
      },
      {
        text: 'الخيارات',
        value: 'actions',
        align: 'center',
      },
    ],
    types: {
      market: {
        type: 0,
        name: 'السوق',
      },
      borsa: {
        type: 1,
        name: 'البورصة',
      },
      gold: {
        type: 2,
        name: 'الذهب',
      },
      all_currencies: {
        type: '',
        name: 'جميع العملات',
      },
    },
  }),
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      const type = this.types[this.$route.name.split('.').pop()];
      store.state.pageTitle = type.name;
      const response = await axios.get(`/currency/${type.type}/`);
      this.data = response.data.data
      this.loading = false
    },
    dialogModal(dialog, type = this.dialogData.type, data = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.data = data;
    },
    async updateBursaState(item, state) {
      const old = item.is_closed;
      try {
        item.is_closed = state;
        const formData = new FormData();
        formData.append('id', item.id);
        formData.append('state', state);
        await axios.post('/bursa/updateState', formData);
        await toast.dispatch('success', 'تم تحديث بنجاح')
      } catch (e) {
        item.is_closed = old;
        await toast.dispatch('error', 'حصل خطأ')
      }
    },
    diffDays(end, start = new Date()) {
      const diffInMs = end - start;
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      return Math.ceil(diffInDays);
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
