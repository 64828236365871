<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate || isPriceUpdate, 'form-primary': isCreate}"
      >
        <span v-if="item && isPriceUpdate" class="">{{ $('price_update', [types[form.type].name]) }}</span>
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDelete && item" class="my-3">
          {{ $('delete_msg', [item.title]) }}
        </h2>
        <v-form
          v-if="!isDelete"
          ref="form"
          v-model="valid"
        >
          <v-container v-if="isCreate || isUpdate">
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.name"
                  v-max-length="200"
                  label="اسم العملة"
                  :rules="rules.name"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="form.type"
                  label="نوع العملة"
                  :items="types"
                  item-text="name"
                  item-value="type"
                  :rules="rules.type"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="isCreate || isPriceUpdate">
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.sell_price"
                  v-max-length="200"
                  label="سعر البيع"
                  :rules="rules.sell_price"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.buy_price"
                  v-max-length="200"
                  label="سعر الشراء"
                  :rules="rules.buy_price"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate || isPriceUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'Dialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
    // eslint-disable-next-line vue/require-prop-types
    type: {
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    types: [
      {
        type: 0,
        name: 'السوق',
      },
      {
        type: 1,
        name: 'البورصة',
      },
      {
        type: 2,
        name: 'الذهب',
      },
    ],
    form: {
      id: '',
      name: '',
      type: '',
      buy_price: '',
      sell_price: '',
    },
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isPriceUpdate() {
      return this.data.type === 'priceUpdate'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    rules() {
      const rules = {};
      rules.name = this.isPriceUpdate ? [] : [
        v => !!v || this.$t('form.required', ['اسم العملة']),
      ];
      rules.type = this.isPriceUpdate ? [] : [
        v => v !== '' || this.$t('form.required', ['نوع العملة']),
      ];
      rules.sell_price = this.isPriceUpdate || this.isCreate ? [] : [
        v => v !== '' || this.$t('form.required', ['سعر البيع']),
      ];
      rules.buy_price = this.isPriceUpdate || this.isCreate ? [] : [
        v => v !== '' || this.$t('form.required', ['سعر الشراء']),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.data) {
            this.item = val.data;
            this.form.id = val.data.id;
            this.form.name = val.data.name;
            this.form.type = val.data.type;
            this.form.buy_price = val.data.prices[0].buy_price;
            this.form.sell_price = val.data.prices[0].sell_price;
          } else {
            this.form.type = this.type;
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  methods: {
    async submit() {
      try {
        const formData = new FormData();
        formData.append('id', this.form.id);
        this.submitLoading = true;
        if (this.isPriceUpdate || this.isCreate) {
          formData.append('sell_price', this.form.sell_price);
          formData.append('buy_price', this.form.buy_price);
        }
        if (this.isPriceUpdate) {
          formData.append('currencyID', this.form.id);
          await axios.post('/price/create', formData);
          await toast.dispatch('success', 'تم تحديث الاسعار بنجاح')
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        if (this.isDelete) {
          await axios.post('/currency/delete', formData);
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        if (this.$refs.form.validate() === false) {
          return;
        }
        formData.append('name', this.form.name);
        formData.append('type', this.form.type);
        if (this.isCreate) {
          await axios.post('/currency/create', formData);
          await toast.dispatch('success', this.$t('form.success.create'))
        } else if (this.isUpdate) {
          await axios.post('/currency/update', formData);
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    fileChanged() {
      if (!this.form.image) {
        this.imagePreview = null

        return null
      }
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.imagePreview = reader.result
      }
      reader.onerror = error => {
        console.log('Error: ', error)
      }

      return null
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.item = null;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
